import 'scripts/polyfills';
import { createRoot } from 'react-dom/client';
import 'scripts/i18n';
import { App } from './app';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import CONFIG from 'scripts/util/constants/config';
import React from 'react';

(async () => {
  /* process.env is coming from EnvironmentPlugin (which is basically just DefinePlugin)
    It is evaluated during build time of a bundle.
    https://webpack.js.org/plugins/environment-plugin/
  */
  require('@rally/creation-matrix/dist/css/uhc.css');

  const div = document.createElement('div');
  div.setAttribute('id', 'arcade-ui-app');
  if (!document.body) {
    throw new Error('Document has no body');
  }
  document.body.appendChild(div);
  document.body.classList.add('rds-on');
  const root = createRoot(div);
  document.body.classList.remove('advantage');
  const ldClientID = CONFIG.ARCADE_WEB_LAUNCHDARKLY_CLIENT_ID;
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientID,
  });
  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
  );
})();
