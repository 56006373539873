import {
  ChatVendorType,
  IMemberInfo,
  IMobileHoops,
  ISegmentationData,
} from 'scripts/api/live-person/live-person.interfaces';
import { IProfileUser, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { parseMemberInfo } from 'scripts/hooks/use-live-person/live-person-utils';
import {
  getLivePersonEligibilityError,
  getLivePersonEligibilityLoading,
  getLivePersonEligibilitySuccess,
} from 'scripts/reducers/live-person-reducer';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { getMobileHoopsError, getMobileHoopsSuccess } from 'scripts/reducers/mobile-hoops-reducer';

/**
 * EI requires an additional eligibility check
 * All CS and MR members are currently eligible
 */
export function getLivePersonSegmentationData(
  user: IProfileUser,
  getSegmentationData?: (memberInfo: IMemberInfo) => Promise<ISegmentationData>,
  isOptumLpScriptLoaded?: boolean,
): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    if (user.lineOfBusiness === LineOfBusiness.EI) {
      dispatch(getLivePersonEligibilityLoading());
      try {
        const memberInfo = parseMemberInfo(user, isOptumLpScriptLoaded);
        // If there's any issue with creating the memberInfo payload, make user ineligible
        if (!memberInfo) {
          dispatch(getLivePersonEligibilitySuccess({ eligible: false }));
          return;
        }
        const result = await getSegmentationData(memberInfo);
        const eligible = result.chatVendor === ChatVendorType.LivePerson;
        dispatch(getLivePersonEligibilitySuccess({ eligible, segmentationData: result }));
      } catch (error) {
        console.error('Error checking live chat eligibility with getSegmentationData.', error);
        dispatch(getLivePersonEligibilityError());
      }
    } else if (user.lineOfBusiness === LineOfBusiness.CS) {
      const memberInfo = parseMemberInfo(user);
      // If there's any issue with creating the memberInfo payload, make user ineligible
      if (!memberInfo) {
        dispatch(getLivePersonEligibilitySuccess({ eligible: false }));
        return;
      }
      dispatch(getLivePersonEligibilitySuccess({ eligible: true }));
    } else {
      dispatch(getLivePersonEligibilitySuccess({ eligible: true }));
    }
  };
}

export function getLivePersonChatHoopsData(
  getLivePersonChatHoops?: () => Promise<IMobileHoops>,
): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      const result = await getLivePersonChatHoops();
      dispatch(getMobileHoopsSuccess({ mobileHoops: result }));
    } catch (error) {
      console.error('Error getting live person chat hoops.', error);
      dispatch(getMobileHoopsError());
    }
  };
}
